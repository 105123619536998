@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito", sans-serif;
}
.dashboard-sidebar::-webkit-scrollbar {
  display: none;
}
.table-container {
  overflow: auto; /* Ensure the table is scrollable */
}

.table-container::-webkit-scrollbar {
  width: 2px; /* Narrow scrollbar width */
  height: 4px; /* Narrow scrollbar height for horizontal scroll */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

.table-container::-webkit-scrollbar-thumb {
  background: #888; /* Thumb (scrollbar handle) color */
  border-radius: 4px; /* Rounded edges for the thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* For Firefox (uses scrollbar-width and scrollbar-color) */
.table-container {
  scrollbar-width: thin; /* Narrow scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
}

.multiselect-container {
  position: relative;
}

.multiselect-container .rmsc {
  --rmsc-main: #4299e1;
  --rmsc-hover: #ebf8ff;
  --rmsc-selected: #bee3f8;
  --rmsc-border: #a0aec0;
  --rmsc-gray: #4a5568;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}

.multiselect-container .rmsc .dropdown-container {
  position: absolute;
  z-index: 10000;
  width: 100%;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.multiselect-container .rmsc .dropdown-content {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
}

.EditorStyle{
  border: 1px solid gray;
}

.gradient-text {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(
    to right,
    #ff6b6b,
    #4ecdc4,
    #45b7d1,
    #96c93d,
    #ff6b6b
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
input[type="datetime-local"]:not([value])::-webkit-datetime-edit {
  opacity: 0;
}

input[type="datetime-local"]:not([value])::-webkit-datetime-edit-fields-wrapper {
  opacity: 0;
}




/* Add this to your CSS */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

input[type="datetime-local"]::-webkit-datetime-edit-minute-field {
  -webkit-appearance: none;
  appearance: none;
}

/* Optional: Style the time picker dropdown */
input[type="datetime-local"]::-webkit-time-picker {
  min-height: 200px;
}



.btn {
  padding: 10px 20px;
  background-color: #36498f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #2c3e50;
}

.textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.timer-container {
  isolation: isolate;
  contain: content;
}

.timer-wrapper {
  contain: content;
  will-change: contents;
}


@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

.swiper {
  padding: 2rem 0;
  width: 100%;

}

.swiper-slide {
  width: 320px;
  height: auto;
  transition: transform 0.3s ease;

}

.swiper-button-next,
.swiper-button-prev {
  color: #9333ea !important;
}

.swiper-pagination-bullet-active {
  background: #9333ea !important;
}

.swiper-slide-active {
  transform: scale(1.1);
  z-index: 2;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background-image: linear-gradient(
    to left,
    rgba(147, 51, 234, 0.15),
    rgba(147, 51, 234, 0)
  ) !important;
}


.mask-border {
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}


.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}






@keyframes text-scale {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.animate-border {
  animation: border-pulse 2s linear infinite;
}

.animate-pulse-scale {
  animation: text-scale 2s ease-in-out infinite;
  display: inline-block; /* Required for transform */
}